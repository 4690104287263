.fields-split-columns{
  display: grid;
  column-gap: 1.5rem;

  &.two {
    grid-template-columns: 1fr 1fr;
  }

  &.tree {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &.four {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

