.header-card {
  &__subtitle {
    margin-bottom: 10px;
    font-size: 11pt;
  }
  &__title {
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 21px;
    color: rgb(74, 80, 91);
  }
  &__inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 50px;
  }
}
