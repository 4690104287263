.dialog__title {
  border-bottom: 2px #cccccc solid;
  .close-button {
    position: absolute;
    right: 1px;
    top: 1px;
  }
}

.dialog__title {
  cursor: grab;
}
.react-draggable-dragging > .dialog__title {
  cursor: grabbing !important;
}
