

.info-panel{
  &__draggable {
    z-index: 1;
    position: fixed;
    left: 10%;
    top: 20%;
    text-transform: none;
    cursor: grab;
}

  &__body {
    max-height: 10000px;
    overflow-Y: hidden;
  }

  &__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__body__close {
    max-height: 0;
  }

  &__icon-hide-body {
    min-width: 60px;
    min-height: 60px;
  }

  &__icon-hide-body__active {
    transform: rotate(180deg);
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    margin: 0;
    border-bottom: 2px #cccccc solid;
    .close-button {
      position: absolute;
      right: 1px;
      top: 1px
    }
  }
  &__size {
    &--xs {
      max-width: 444px;
    }
    &--sm {
      max-width: 600px;
    }
    &--md {
      max-width: 960px;
    }
    &--lg {
      max-width: 1280px;
    }
    &--xl {
      max-width: 1920px;
    }
  }

  &.react-draggable-dragging {
    cursor: grabbing!important;
  }
}