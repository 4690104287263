.table-documents {
  margin-bottom: 2rem;
  &-header {
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      margin-bottom: 15px;

      & h5 {
        text-transform: uppercase;
      }
    }

    &__button-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 10px;
    }
  }
}

.document-table__container {
  overflow: auto;
}
