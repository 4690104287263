.tabs-card-header__container {
  margin-top: 10px;
  background-color: #f7f8f8;
  border-top: 1px solid #e9eaeb;
  text-transform: uppercase;
  //margin: 0 -1.5rem;
  //padding: 0 1.5rem;
}


