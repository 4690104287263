.tile-layer-wrap {
  z-index: 2;
  position: absolute;
  background-color: rgba(28, 42, 52, 0.95);
  color: white;
  top: 24px;
  left: 2%;
  padding: 5px;
}
.tile-layer-wrap-main {
  bottom: calc(6% + 255px);
  top: auto;
}
.tile-layer-input {
  margin: 0px 10px;
}
.tile-layer-label {
  margin-left: 4px;
}
