.sorting-column {
  padding: 12px;
  color: #6a6d78;
  font-size: 12px;
  text-align: center;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;

  a {
    color: #428bca;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  span:after {
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -10px;
    bottom: 16px;
    display: block;
    font-family: 'Glyphicons Halflings';
  }

  &.order-none span:after {
    content: '\e150';
    opacity: 0.2;
  }

  &.order-up span:after {
    color: #fd2626;
    opacity: 1 !important;
    content: '\e156';
    position: absolute;
  }

  &.order-down span:after {
    color: #fd2626;
    opacity: 1 !important;
    content: '\e155';
  }
}
