.subobject-panel__item {
  font-size: 13px;

  &-active {
    color: red;
    text-decoration: none;

    &:focus {
      color: red;
    }
    &:hover {
      color: #dd0000;
      text-decoration: none;
    }
  }
}
