.popup-overlay {
  margin: 0px 0px 13px 6px;
}
.popup {
  background-color: rgba(28, 42, 52, 0.9);
  padding: 15px;
  border-radius: 10px;
  max-width: 600px;
  color: white;
}
.popup:before {
  bottom: -20px;
  border: solid transparent;
  content: '\00a0';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: rgba(28, 42, 52, 0.9);
  border-width: 10px;
  left: calc(50% - 5px);
  margin-left: -10px;
}
.popup-title {
  padding: 5px 0px 10px;
  border-bottom: 1px solid;
}
.popup-content {
  padding-top: 10px;
}
.popup-closer {
  text-decoration: none;
  position: absolute;
  top: 8px;
  right: 8px;
  background: url(../../../../assets/images/cross-close.png) no-repeat;
  width: 14px;
  height: 14px;
}
