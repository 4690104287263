.ods-field-tooltip {
  position: absolute;
  left: 0;
  bottom: -17px;
  z-index: 10;
  display: inline-block;
  padding: 8px 12px;
  font-size: 12px;
  line-height: 1.2;
  color: #696969;
  background-color: #ebebeb;
}
