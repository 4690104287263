.multiTable {
  font-size: 12px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid darken(#eceeef, 10%);

  th,
  td {
    padding: 7px 10px;
  }

  table {
    // margin: 0;
  }

  &__base {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }

  @-moz-document url-prefix() {
    .header table {
      table-layout: fixed;
    }
  }

  &__header {
    table {
      border: 0;
      border-bottom: 1px solid darken(#eceeef, 10%);

      tr:first-child {
        th,
        td {
          border-top: 0;
        }
      }
    }

    th,
    td {
      background: #ecedee linear-gradient(white, #e6e6e6);
      border-top-color: darken(#eceeef, 10%);
      border-right-color: darken(#eceeef, 10%);
      border-left: 0;
      border-bottom: 0;
    }
  }

  &__main {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    th,
    td {
      min-width: 150px; // defaults
    }
  }

  &__header,
  &__footer {
    flex-shrink: 0;

    th,
    td {
      width: 150px; // defaults
    }
  }
}
