
.Toasty__toast {
  right: 85px;
  overflow: hidden;
}

.wrapper-toast__button {
  margin: 10px 0;
}

.toasty-text {
  margin: 0;
  padding: 0;
  word-break: break-word;

}

.toasty__text-to-clipboard {
  max-height: 500px;
  overflow-y: auto;
  width: 300px;
}

