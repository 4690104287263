//общие стили для всех
.display-grid {
  display: grid;
}
.gap-1 {
  gap: .25em;
}
.gap-2 {
  gap: .5em;
}
.gap-3 {
  gap: 1em;
}
.gap-4 {
  gap: 1.5em;
}