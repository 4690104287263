.right-panel-card-container {
  color: white;
  position: absolute;
  right: 1%;
  width: 30%;
  top: 2%;
  bottom: 2%;
  z-index: 3;
  display: flex;
  flex-direction: column;

  &__hide {
    height: fit-content;
  }

  &__header {
    height: fit-content;
    background: rgba(28, 42, 52, 0.95);
    & .right-panel-card-container-header__top {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      & > h4 {
        text-transform: uppercase;
        margin: 0;
        font-weight: 400;
      }
    }
  }

  &__body {
    background-color: rgba(4, 4, 16, 0.9);
    overflow-y: auto;
    color: white;
    position: relative;
    height: 100%;
    padding-bottom: 20px;

    &__hide {
      height: 0;
      padding-bottom: 0;
      overflow-y: hidden;
      bottom: auto;
    }
  }
}

.close-panel-card__container {
    height: fit-content;
}