.equipment-add {
  color: white;
  text-transform: uppercase;
  background: green;
  text-shadow: none;
  margin-right: 12px;
  padding: 8px 14px;
  text-align: center;
  border: none;
  cursor: pointer;
  font-size: 12px;
  outline: none;

  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.15);
  }
}

.equipment-delete {
  color: white;
  text-transform: uppercase;
  background: red;
  text-shadow: none;
  margin-right: 12px;
  padding: 8px 14px;
  text-align: center;
  border: none;
  cursor: pointer;
  font-size: 12px;
  outline: none;

  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.15);
  }
}
