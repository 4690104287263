.legendPanel {
  position: absolute;
  bottom: 3%;
  left: 2%;
  width: 61%;
  z-index: 2;
  background-color: rgba(28, 42, 52, 0.95);
  color: #fff;
  max-width: 525px;

  ul {
    li {
      width: 210px;
    }
  }
}

.level {
  display: inline-block;
  vertical-align: middle;
  background-color: rgba(255, 255, 255, 1);
  width: 15px;
  height: 15px;
  border-radius: 7px;
  border: 1px solid #eceeef;
}
