.filter-card__wrapper {
   display: flex;
   flex-wrap: wrap;
   margin-bottom: 15px;
   gap: 50px;

  & > .filter-card-fields__wrapper {
    display: flex;
    gap: 20px;
  }

  & > .filter-card-btn__wrapper {
    display: flex;
    align-items: center;
  }
}


