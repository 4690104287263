.formAuth {
  &__header {
    background-image: linear-gradient(0deg, #0a0a0a, #0a0a0acc);
    color: #babbbc;
    height: 56px;
    line-height: 56px;
    padding: 0 25px;

    h1 {
      font-size: 24px;
      margin: 0;
      display: inline;
    }
  }

  &__main {
    padding: 1rem;
    max-width: 21rem;
  }

  &__footer {
    padding: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}

.submitIcon {
  color: #fff;
  width: 18px;
  height: 18px;
  font-size: 18px;
  margin-right: 5px;
}

.errorMessage {
  color: #f44336;
  font-size: 14px;
  max-width: 270px;
}
