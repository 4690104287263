.navigation {
  padding-top: 14px;
  padding-bottom: 14px;
  min-height: 74px;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }

  .nav-label {
    line-height: 26px;
    font-size: 14px;
    color: #555;
    float: left;
    padding-left: 10px;
  }

  .nav-rows {
    margin-left: 20px;
    float: left;
  }

  .nav {
    white-space: nowrap;
    float: right;
    padding-right: 10px;
    padding-left: 20px;

    cursor: not-allowed;
    color: #101318;

    span.next,
    span.last,
    span.prev,
    span.first {
      opacity: 0.5;
    }
    .goto a,
    .goto em,
    a.next,
    a.last,
    a.prev,
    a.first,
    span.next,
    span.last,
    span.prev,
    span.first {
      padding: 6px 12px;
    }

    .goto em {
      background-color: #101318;
      color: #fff;
      font-style: normal;
    }

    a.next:hover,
    a.last:hover,
    a.prev:hover,
    a.first:hover,
    span.goto:hover {
      background: white;
    }

    a {
      color: #101318;
      text-decoration: none;
    }
    .active {
      cursor: pointer;
    }
  }
}
