#add-button,
#edit-button,
#copy-button,
#block-button,
#unblock-button {
  margin-right: 10px;
}

#full-export-button {
  margin-left: 10px;
}

.tab-pane-list {
  background-color: white;
}

.table-container {
  .selected {
    background-color: #e5e5e5;
  }

  .summary-cell {
    width: 100%;
  }
}
