.legendPanel {
  bottom: 3%;
  left: 2%;
  width: 61%;
  background-color: rgba(28, 42, 52, 0.95);
  padding: 15px;
  color: #fff;
  max-width: 394px;
}

.legend_finished {
  display: inline-block;
  vertical-align: middle;
  background-color: green;
  width: 15px;
  height: 15px;
  border-radius: 7px;
  border: 1px solid #eceeef;
}

.legend_planned {
  display: inline-block;
  vertical-align: middle;
  background-color: #c1c100;
  width: 15px;
  height: 15px;
  border-radius: 7px;
  border: 1px solid #eceeef;
}

.legend_in_progress {
  display: inline-block;
  background-color: red;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  border-radius: 7px;
  border: 1px solid #eceeef;
}

.text_finished,
.text_planned {
  color: gray;
}

.text_in_progress {
  color: black;
}
