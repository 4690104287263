.versions {
  .id__col {
    min-width: 165px;
  }
  .owner_name__col {
    min-width: 165px;
  }
}

.clearBtn {
  float: left;

  button {
    background-color: #51c778 !important;

    span {
      color: #fff !important;
    }
  }
}

.diffBtn {
  float: right;

  button {
    background-color: gray !important;

    span {
      color: #fff !important;
    }
  }
}

.errorMsg {
  text-align: right;
  color: red;
  margin: 0 0 0.5rem 0;
}
