.left-panel {
  &__button-group {
    display: flex;
    background: #eee;
    justify-content: space-between;
    padding-right: 25px;
    &__container {
      margin: 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}