.cert-info {
 background-image: url(./cert.png) !important;
  border: 5px double #5a9251;
  padding: 10px;
  margin: 10px 0px 0px 0px;
 &__container {

  display: grid;
  grid-gap: 3px 6px;
  grid-template-columns: 30% 70%;
  font-size: .75em;
 }
 &__prefix {
  font-weight: bolder;
 }
 &__header {
  line-height: 1.5;
  margin: 0;
 }
}

