@import 'assets/styles/variables';

.legend_add {
  display: inline-block;
  vertical-align: middle;
  background: green;
  width: 15px;
  height: 15px;
  border-radius: 7px;
  border: white 1px solid;
}

.legend_update {
  display: inline-block;
  background: #c1c100;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  border-radius: 7px;
  border: white 1px solid;
}

.legend_del {
  display: inline-block;
  vertical-align: middle;
  background: red;
  width: 15px;
  height: 15px;
  border-radius: 7px;
  border: white 1px solid;
}

.legend_move {
  display: inline-block;
  vertical-align: middle;
  background: #1fbcd2;
  width: 15px;
  height: 15px;
  border-radius: 7px;
  border: white 1px solid;
}

.diff__legend .tile-layer-wrap {
  position: relative;
  margin-top: -85px;
  width: 122px;
  margin-left: -10px;
}

.diff__tabs .MuiTabs-indicator {
  background: #1976d2;
}
