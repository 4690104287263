.accordion-card__container {
  cursor: pointer;
  margin: 0;
  padding: 0;
  list-style: none;
  color: #7a8083;
  user-select: none;


  & a {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: white;
      transition: .3s;
    }
  }

  & p {
    margin: 0;

    &:hover {
      color: white;
      transition: .3s;
    }
  }

  & > .accordion-header-card {
    display: flex;
    align-items: center;
    padding: 2px 5px;
    list-style: none;
    color: #7a8083;
    text-decoration: none;



    &:hover {
      color: white;
      background-color: #1c2b36;
      transition: .3s;
    }
  }

  & > .active-accordion-header-card {
    color: white;
    background-color: #1c2b36;
    transition: .3s;
  }

  & > .accordion-body-card__list {
    list-style: none;
    max-height: fit-content;
    overflow: hidden;
    margin: 0;
    display: block;
    box-sizing: border-box;
    //animation: fadein .4s;
    padding-left: 15px;

    & > .active-accordion-item {
      background-color: #1c2b36;
      transition: .3s;
      color: white;
    }

    & > .accordion-body-card__item {
      margin: 0;
      padding: 6px 5px 6px 15px;
      display: block;
      &:hover {
        color: white;
        background-color: #1c2b36;
        transition: .3s;
      }

      & > p {
        margin: 0;
      }


    }
  }

  & > .open-accordion {
    max-height: 0;
  }

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
}