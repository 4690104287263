@import 'assets/styles/variables';

.type-navigation-bar {
  height: 43px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &-wrapper {
  }

  .header-control {
    .breadcrumb {
      .home-icon {
        margin-left: 0;
      }
    }
  }

  .reg-nav-wrapper {
    margin: 0 auto;
    max-width: $ods-navbar-max-width;
  }

  &__breadcrumb {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.7);
    vertical-align: middle;
    text-decoration: none;
    line-height: 40px;

    &:before {
      content: '\E5CC';
      color: rgba(255, 255, 255, 0.7);
      vertical-align: top;
      display: inline-block;
      font-size: 25px;
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      margin: 0 10px 0 8px;
      -webkit-font-smoothing: antialiased;
    }

    .home-icon {
      vertical-align: top;
      display: inline-block;
      font-size: 25px;
      margin-top: 6px;
    }

    &:first-child:before {
      display: none;
    }

    &:last-child {
      color: #fff;

      & > i {
        color: #fff;
      }
    }
  }
}
