.ods-core-left-panel {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 24px;
  bottom: 3%;
  left: 2%;
  background-color: white;
  width: 65.5%;
  overflow: hidden;
  z-index: 3;

  h2 {
    color: #4a505b;
    font-size: 18px;
    text-transform: uppercase;
  }

  .header {
    padding: 24px;
    padding-top: 14px;
  }

  .btn-group {
    top: 0;
    min-height: 74px;
  }

  .btn-group,
  .btn-group-other {
    position: relative;
    background-color: #f2f3f4;
    padding: 20px 24px;

    .return-back {
      position: absolute;
      right: 30px;
      top: 13px;
      color: rgba(0, 0, 0, 0.4);
      cursor: pointer;

      &:hover {
        color: rgba(0, 0, 0, 0.7);
      }

      svg {
        transform: scale(1.3);
      }
    }

    button {
      margin-right: 12px;
    }

    #add,
    #change {
      background: #51c778;
    }

    #approve {
      background: #51c778;
    }

    #reject {
      background: #c6a906;
    }

    .hidden {
      display: none;
    }

    .show {
      display: inline-block;
    }

    .adding {
      float: right;
      background: #51c778;
      margin-top: 10px;

      &:after {
        clear: right;
      }
    }
  }

  .tabs {
    text-align: left;
    text-transform: uppercase;
    padding: 10px 0 0;
    margin: 1rem -1.5rem;
    background-color: #f7f8f8;
    border-top: 1px solid #e9eaeb;

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      display: inline-block;
      margin-right: 20px;
      padding-bottom: 7px;

      &.active {
        border-bottom: 2px solid red;
      }
      &.hover {
        cursor: pointer;
      }
    }
  }

  .traffic_signs_id {
    .Select-menu-outer {
      top: auto;
      bottom: 100%;
    }
  }
}

.ods-core-left-panel__content {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.ods-value--old {
  input {
    background-color: rgb(250, 255, 189) !important;
  }
  div {
    background-color: rgb(250, 255, 189) !important;
  }
}

.ods-value--deleted {
  input {
    background-color: #f24841 !important;
  }
  div {
    background-color: #f24841 !important;
  }
}
