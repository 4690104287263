.comparison-link {
  &__container {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 4px 4px 0;
  }

  &__left {
      display: flex;
  }

  &__icon {
    font-size: 18px;
  }

  &__text {
  font-size: 0.875rem;
    margin: 0 0 0 8px ;
  }

  &__count {
    font-size: 0.75rem;
  }
}