.group-container {
  margin: 1.5rem 0;
  & h2 {
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  &__children {
    //overflow: auto;
  }

  &__icon {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;

    &--active{
      transform: rotate(180deg);
    }
  }
}