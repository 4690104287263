.treeEdit {
  $width: 1280px;

  .width {
    width: $width;
  }

  .outHeight {
    height: 260px;
    display: flex;
    flex-direction: column;
  }

  .out {
    tr {
      cursor: pointer;
    }

    tr.disabledRow {
      cursor: not-allowed;
    }

    td:first-child {
      min-width: auto;
    }
  }

  .selectedRow {
    td {
      background-color: #e0e0e0;
    }
  }

  .disabledRow {
    td {
      background-color: #e0e0e0;
    }
  }
}
