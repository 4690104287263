@import '~font-awesome/css/font-awesome.min.css';
@import '~roboto-fontface/css/roboto/roboto-fontface.css';
@import '~material-design-icons-iconfont/dist/material-design-icons.css';
@import '~glyphicons-only-bootstrap/css/bootstrap.min.css';
@import './variables';

html,
body {
  background-color: $ods-background;
  color: $body-color;
  height: 100%;
}

button,
input,
optgroup,
select,
textarea {
  font-family: $font-family-base;
}

#app {
  height: 100%;
  min-height: 100%;
  min-width: 975px; // /images/odsRegistryStartup/bg.jpg
  width: 100%;
}

.form-control {
  font-family: $font-family-base;
}

button {
  cursor: pointer !important;
}

button:focus {
  outline-color: transparent;
  outline: none;
}

button[disabled] {
  cursor: not-allowed !important;
}

.ods-registry-bg {
  background-color: #eff0f3;
}

.bg-white {
  background-color: #fff;
}

.ods-form-bg {
  $background: #191c23;
  background-color: $background;

  // https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
  // для autocomplete material-ui инпутов
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 300px $background inset;
    -webkit-text-fill-color: rgb(255, 255, 255) !important;
  }
}

// уменьшаем высоту инпута, для всплывающей подсказки
.ods-mui-text-field {
  input {
    width: 270px;
  }
}

.ods-mui-select-field.disabled > div > div > div {
  color: rgba(0, 0, 0, 0.3) !important;
}

.ods-mui-checkbox {
  display: inline-block !important;
  width: auto !important;

  label {
    width: auto !important;
  }
}

.ods-head-form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  & > * {
    flex: 0 0 auto;
  }
}

.position-relative {
  position: relative !important;
}

.display-flex {
  display: flex;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-auto-y {
  overflow-y: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-hidden-x {
  overflow-x: hidden !important;
}
.visibility-hidden {
  visibility: hidden !important;
}
.position-absolute {
  position: absolute;
}

.h100 {
  .MuiPaper-root {
    height: 100%;
    .MuiTableContainer-root {
      height: 100%;
      .MuiTable-root {
        height: 100%;
      }
    }
  }
}

input[disabled] {
  color: #8c8c8c !important;
}

.ods-version-props {
  font-size: 14px;
  display: flex;

  dt,
  dd {
    width: 50%;
  }

  dd {
    text-decoration: underline;
    font-style: italic;
  }
}

.color-red {
  color: red !important;
}
.color-000 {
  color: #000 !important;
}

.ods-typeahead .Select-input {
  input {
    max-width: 200px;
  }
}

.ods-header-search-form {
  background-color: $dark-form-background;
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 300px $dark-form-background inset;
    -webkit-text-fill-color: rgb(255, 255, 255) !important;
  }
  // max-width: 1280px;
  width: 90% !important;

  &.white {
    background-color: #fff;
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 300px #fff inset;
      -webkit-text-fill-color: rgb(255, 255, 255) !important;
    }
  }
}

.nicescroll-rails {
  z-index: 5 !important;
}

.ods-before-table {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  color: #3e4450;

  h1 {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: normal;
    margin: 0;
  }
}

.ods-btn-group {
  $margin: 0.4rem;

  margin-left: -$margin;
  margin-right: -$margin;

  & > * {
    margin-left: $margin;
    margin-right: $margin;
  }
}

.tech-registry-table-toolbar {
  padding: 0.8rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 1.4rem;
    font-weight: normal;
    margin: 0;
  }

  .btn-group {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    & > * {
      margin-left: 0.4rem;
      margin-right: 0.4rem;
    }
  }
}

.registry-table-max-height {
  max-height: 400px;
}

.registry-table-toolbar {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  h1 {
    font-size: 1.4rem;
    font-weight: normal;
    margin: 0;
  }

  .btn-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;

    .btn {
      background-color: #51c778;
      border: none;
      height: 34px;
      font-size: 0.8rem;
      text-transform: uppercase;
      width: 165px;
      color: white;
      text-align: center;
    }
  }
}

// DEPRECATED {{{
.before-table {
  flex-shrink: 0;
  padding: 0.8rem 0;
  font-size: 1.2rem;
  color: #3e4450;

  h1 {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: normal;
    margin: 0;
  }

  .btn-group {
    float: right;

    .btn {
      background-color: #51c778;
      border: none;
      height: 34px;
      font-size: 0.8rem;
      text-transform: uppercase;
      width: 165px;
      color: white;
      text-align: center;
    }
  }
}

#add-button,
#edit-button,
#copy-button,
#block-button,
#unblock-button {
  margin-right: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #eff0f3;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 2px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(#c1c1c1, 0.5);
}

.mr-6 {
  margin-right: 2rem !important;
}

.pb-6 {
  padding-bottom: 2rem !important;
}

.ReactTable .rt-noData {
  z-index: 0;
}

.flex100overflow {
  display: flex;
  width: 100%;
  flex-direction: column;
  div {
    div {
      div {
        position: absolute;
      }
    }
  }
}
.relative {
  overflow: auto;
  position: relative;
  width: 100%;
}
html {
  .formControlLabel__space-beetwin {
    flex: 1;
    label {
      justify-content: space-between;
      margin-top: auto;
      span:first-of-type {
        width: auto !important;
        min-width: auto !important;
      }
    }
  }
}

label {
  margin-bottom: 0;
}
