.wrapper-buttons__right-panel {
  display: flex;
  margin-left: .5rem;
  color: white;
}

.wrapper-text__right-panel {
  align-items: center;
  display: flex;
  justify-content: space-between ;
  padding-right: .6rem;
  width: 100%;
}
