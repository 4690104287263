.intercepted-type {
  min-width: fit-content;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 10;

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    background: #1c2a34;
    min-width: fit-content;
    width: 100%;


    & li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: .1rem .2rem;
      min-width: 4rem;
      border-bottom: .15rem solid #1c2a34f2;
    }

    li:hover {
      background-color: #3d4852;
      cursor: pointer;

    }

    & .active {
      border-bottom: .15rem solid red;
    }

    & p {
      margin: 0;
      padding: 0;
      font-size: .7rem;
    }
  }
}