.pre-louder__background {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(28, 42, 52, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fade .3s;
}

.button-toggle {
  position: relative;

}

.pre-louder {
  width: 80%;
  height: 80%;
  border: 5px solid transparent;
  border-top: 5px solid #3498db;
  animation: rotation .8s linear infinite;
  border-radius: 50%;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}