.map-edit {
  background: rgba(28, 42, 52, 0.95);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  min-width: 35px;
  border-top: 1px solid #3d4950;
  border-bottom: 1px solid #3d4950;

  & .button-toggle {
    color: rgba(255, 255, 255, 1);
    text-align: center;
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    padding-top: 5px;

    &:hover:not([disabled]) {
      box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.15);
    }

    &:active:not([disabled]) {
      box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.2);
    }

    &:disabled {
      cursor: not-allowed;
      color: rgba(179, 178, 178, 0.3);
    }
  }

  .active {
    color: #0098d8;
  }
}

.geom-button {
  height: 24px;
  width: 24px;
}

.check-geometry-on {
  background-image: url(../../../../../assets/images/intersect_orange.png);
}

.check-geometry-off {
  background-image: url(../../../../../assets/images/intersect_blue.png);
}

.check-geometry-reon-off {
  background-image: url(../../../../../assets/images/reon_blue.png);
}

.check-geometry-reon-on {
  background-image: url(../../../../../assets/images/reon_orange.png);
}

.show-children-on {
  background-image: url(../../../../../assets/images/node_orange.png);
}

.show-children-off {
  background-image: url(../../../../../assets/images/node_blue.png);
}

.neighbours-on {
  background-image: url(../../../../../assets/images/sosedi_orange.png);
}

.neighbours-off {
  background-image: url(../../../../../assets/images/sosedi_blue.png);
}

.district-on {
  background-size: contain;
  background-image: url(../../../../../assets/images/district_on.png);
}

.district-off {
  background-size: contain;
  background-image: url(../../../../../assets/images/district_of.png);
}
