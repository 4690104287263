.copy-to-clipboard{
  &__button{
    &-text {
      cursor: pointer;
    }

  }
  &__text {
    border-radius: 3px;
    padding: 0 .3rem;
    cursor: pointer;
    &:hover {
      background-color: #0000001a;
    }
  }
  &__icon {
    margin-bottom: -3px;
  }
}