.dialog-comparison-version-card {

  &__dialog-table {
    margin-bottom: 20px;
    max-height: 300px;
    overflow-y: auto;
  }

  &__legend {
    padding: 0;
  }

  &__body {
    min-width: 600px;
    padding: 0 5px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-bottom: 20px;
  }

  &__list {
    margin: 0;
    width: 50%;
  }

  &__item {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;

    & > dt {

    }

    & > dd {

      text-decoration: underline;
      font-style: italic;
    }
  }

}
