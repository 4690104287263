.left-card-panel-new__container {
  background-color: white;
  position: absolute;
  left: 1%;
  width: 68%;
  top: 2%;
  bottom: 2%;
  z-index: 3;
  display: flex;
  flex-direction: column;

  & .field__wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  & > .left-panel__header {
    background-color: #eee;
    display: flex;
    justify-content: space-between;
    padding: 15px 24px;

    & > button {
      max-height: 36px;
    }

    & > .buttons__container {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
    }
  }
}

.left-card-panel-new__container__hide {
  display: none;
}