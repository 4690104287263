.ajax-loading-indicator {
  &__content {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    height: 60px;
    width: 60px;
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    z-index: 4242;

    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &__background {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: LightGray;
    opacity: 0.5;
    filter: alpha(opacity=50);
    z-index: 4241;
  }
}
