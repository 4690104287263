.collapse-button {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../../assets/images/collapse.png') 0 100%;
  background-size: cover;
  opacity: 0.5;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  transform: rotateX(180deg);

  &:hover {
    opacity: 0.8;
  }

  &.collapse-button-revert {
    transform: rotateX(0);
  }
}
