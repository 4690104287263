.legend {
  li:not(:last-child) {
    margin-right: 1rem;
  }

  h6 {
    margin-bottom: 15px;
  }

  padding: 15px;
}
