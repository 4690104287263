.filter {
  &__form {
    .filter {
      &__input {
        min-width: 250px;
        width: 270px;
        max-width: 350px;
        flex: 1;

        &-date {
          max-width: 200px;

          &-with-hours {
            max-width: 200px;
            & button {
              padding-left: 0 !important;
            }
          }
        }
        .MuiFormControlLabel-label {
          color: rgba(255, 255, 255, 0.8);
        }
        &-autocomplete {
          flex: 1;
          min-width: 284px;
          max-width: 300px;
          & [role='button'] {
            width: 90px;
            margin-top: 4px;
          }
        }
      }

      &__btn-submit-group {
          margin-left: auto;
          display: flex;
          align-items: flex-end;
        }

    }
  }
}
