@import 'assets/styles/variables';

.ods-nav {
  $background-color: #101010;
  $navbar-brand-font-size: 1.1rem;

  color: $ods-navbar-font-color;
  background-image: $ods-navbar-background-image;
  padding: 5px 15px;

  .item-left {
    float: left;
    height: $ods-navbar-height;
  }

  .links {
    flex-grow: 1;
    margin: 0;
    line-height: $ods-navbar-height;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    color: $ods-navbar-font-color;

      li {
        display: inline-block;
        margin: 0 15px;
      }

      a {
        text-transform: uppercase;
        font-size: 1rem;
      }


    .dropdown-content {
      a {
        font-size: 14px;
      }
    }
  }

  #nav-logo {
    display: inline-block;

    .dropdown-content {
      margin-top: -1px;
    }
  }

  .brand-logo {
    color: $ods-navbar-font-color;
    display: inline-block;
    font-size: $navbar-brand-font-size;
    padding: 0;
    padding-right: 20px;
    white-space: nowrap;

    img {
      margin-right: 10px;
    }
  }

  .nav-weather {
    display: inline-block;

    span {
      margin: 0px 0px 0px 5px;
      font-size: 14pt;
    }
  }

  .nav-link-active {
    color: #ffffff;
  }

  .nav-link {
    padding: 0;
    color: #babbbc;
  }

  .dropdown-content {
    background-color: $background-color;
    position: absolute;
    z-index: 999;
    padding: 10px;
    display: none;
    line-height: normal;

    & > div {
      display: inline-block;
      vertical-align: top;
    }

    & > ul {
      padding: 0 10px;
    }

    & > ul > li {
      display: block;
      margin: 10px 0;
    }

    .helper-caption {
      font-size: 12px;
      margin: 5px;
      display: block;
      margin-right: 20px;
    }

    .helper-caption a {
      font-size: 12px;
      margin: 0;
      padding: 0;
      line-height: normal;
      color: $ods-navbar-font-color;

      &:hover {
        color: white;
      }
    }
  }

  a {
    color: $ods-navbar-font-color;
    text-decoration: none;

    &:hover {
      color: white;
    }
  }

  .active {
    color: white;
  }

  .nav-wrapper {
    height: 100%;
    position: relative;
    max-width: $ods-navbar-max-width;
    margin: 0 auto;
    display: flex;
    align-items: center;
    //justify-content: space-between;
    flex-wrap: wrap;
  }

}


