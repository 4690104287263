.alert-crete-update-card {
  & pre {
    white-space: normal;
  }

  &__buttons__wrapper {
    display: flex;
    justify-content: flex-end;
    min-width: 400px;
  }
}