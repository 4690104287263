.notification-container {
  margin-right: 1em;
  margin-left: 1em;
  &:hover {
    .notification-hint {
      display: block;
    }
  }
}

.notification-hint {
  display: none;
  padding: 5px;
  position: absolute;
  z-index: 9999;
  cursor: default;
  animation: 0.5s fadeIn;
  font-size: 14px;
  background-color: #101010;
  right: 0;

  .notification-hint__content {
    list-style-type: initial;
  }

  .notification-hint__item {
    display: list-item;
    line-height: 32px;
  }
}
