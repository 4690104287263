.drop_down_container {
  position: relative;

  &>.drop_down_title {
    padding: 15px 0;
    cursor: pointer;
  }

  ul {
    position: absolute;
    z-index: 100;
    background-color: #101010;
    right: 0;
    width: fit-content;
    padding: 0 30px;
    margin: 0;

    @media (max-width: 1375px) {
      left: 0;
    }
  }

  &.location_left {
    left: 0;
  }

}



