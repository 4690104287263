.edit_form{

}
.address-form{
  &__content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin: 1rem;
  }
  &__footer{
    display: flex;
    justify-content: flex-end;
    margin: 1rem;
    margin-top: 2rem;
    gap: 1rem;

  }
}