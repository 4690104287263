.tabs-card {
  background-color: #f7f8f8;
  border-top: 1px solid #e9eaeb;
  padding: 0 1rem;
  margin: 1rem -1.5rem;

  & .MuiTabs-flexContainer {
    column-gap: 1rem;
  }

  & .MuiTab-root {
    align-items: start;

    &.Mui-selected {
      border-bottom: 2px solid red;
    }
  }
}
