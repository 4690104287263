.movement-characteristics {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(17, 1fr);
    grid-template-rows: .1fr .1fr 1fr 1fr;

    &>div {
      border: 1px solid
    }
  }
  &__location {
    grid-column: 1 / 1;
    grid-row: 1 / 3;
  }
  &__fact {
    grid-column: 2 / 17;
    grid-row: 1/1 ;
  }
  &__trucks {
    grid-column: 2 / 7;
    grid-row: 2 ;
  }
  &__road-trains {
    grid-column: 7 / 11;
    grid-row: 2;
  }
  &__public-transport {
    grid-column: 11 / 15;
    grid-row: 2;
  }
}

