@import 'assets/styles/variables';

.login {
  background: white url(../../assets/images/login-winter.jpg) no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.edit-password {
  background: white url(../../assets/images/login-winter.jpg) no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;


  &__main {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.header {
  background-image: linear-gradient(to bottom, #1d2029 0%, #0e1115 100%);
  height: $ods-login-header-height;
  font-size: 24px;
  padding: 0 15px;
  position: relative;
  z-index: 1;

  .logoLink {
    text-decoration: none;
    color: #fff;

    h1 {
      font-size: 24px;
      font-weight: normal;
      display: inline;
      margin: 0;
    }
  }

  .logoImg {
    margin-right: 0.9rem;
  }
}

.main {
  padding: $ods-login-header-height $ods-login-header-height $ods-login-header-height * 2;
  min-height: calc(100vh - #{$ods-login-header-height});
}

.wrapper {
  min-height: 400px;
}
