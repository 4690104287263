.out {
}

.agreedState {
  td {
    background-color: rgba(0, 255, 0, 0.5);
  }
}
.rejectedState {
  td {
    background-color: rgba(255, 0, 0, 0.5);
  }
}
.onAgreementState {
  td {
    background-color: rgba(255, 255, 0, 0.5);
  }
}

.legend_add {
  display: inline-block;
  vertical-align: middle;
  background-color: rgba(0, 255, 0, 0.5);
  width: 15px;
  height: 15px;
  border-radius: 7px;
  border: 1px solid #eceeef;
}

.legend_del {
  display: inline-block;
  vertical-align: middle;
  background-color: rgba(255, 0, 0, 0.5);
  width: 15px;
  height: 15px;
  border-radius: 7px;
  border: 1px solid #eceeef;
}

.legend_update {
  display: inline-block;
  background-color: rgba(255, 255, 0, 0.5);
  vertical-align: middle;
  width: 15px;
  height: 15px;
  border-radius: 7px;
  border: 1px solid #eceeef;
}

.legend_not_mandatory {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.5);
  vertical-align: middle;
  width: 15px;
  height: 15px;
  border-radius: 7px;
  border: 1px solid black;
}

.green {
  td {
    background-color: rgba(0, 255, 0, 0.5);
  }
}
.red {
  td {
    background-color: rgba(255, 0, 0, 0.5);
  }
}
.yellow {
  td {
    background-color: rgba(255, 255, 0, 0.5);
  }
}
