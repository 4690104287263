.table_stick-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #cbcbca;

  & .MuiTableCell-head {
    background-color: #dbdbdb;
    outline: 1px solid #c7c7c7;
  }

  &__th {
    & span.table_stick-header__span {
      position: sticky;
      left: 1rem;
      right: 1rem;

      &:hover {
        color: #000000;
        cursor: default;
      }

      & .MuiButtonBase-root.MuiTableSortLabel-root {
        justify-content: center;

        &:hover {
          color: rgba(0, 0, 0, 0.54);
        }
      }
    }

    //& span.MuiButtonBase-root.MuiTableSortLabel-root:hover {
    //  color: rgba(0, 0, 0, 0.54);
    //}
  }
}

td {
  &.MuiTableCell-root {
    word-break: break-word;
    outline: 1px solid #e5e5e5;
    min-width: 120px;
  }
}
