.ods-link-list__label {
  color: black;
  font-size: 11pt;
  width: 100%;
  position: relative;
  height: 40px;
  margin: 0px;
  display: table-cell;
  vertical-align: bottom;
}
