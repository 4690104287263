.card-ogh-global-container{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;
  overflow: hidden;
  background: #d2d2d2;

  &__white {
    background: white;
  }
}
