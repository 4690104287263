:root {
  --posX: 0px;
  --posY: 0px;
}

.mouse-parallax {

  &__bg {
    position: absolute;
    background: url('/assets/images/login-winter.jpg') no-repeat;
    top: -10%;
    left: -10%;
    background-size: cover;
    right: -10%;
    bottom: -10%;
    transition: translate 0.1s;
    transform: translate(calc(var(--posX, 0) * 1px), calc(var(--posY, 0) * .2px));
  }

  &__body {
    transition: translate 0.1s;
    transform: translate(calc(var(--posX, 0) * 3px), calc(var(--posY, 0) * 2px));
  }
}

.no-match {
  margin: 200px;
  position: relative;
  overflow: hidden;
  background-color: #fffffff7 !important;
  &__container {
    position: relative;
    overflow: hidden;
    height: 96vh;
  }

  &__wrapper {
    display: flex;
  }

  &__content {
    text-align: center;
    max-width: 553px;
  }

  &__title {

    font-weight: 300;
    font-size: 250px;
    line-height: 1;
    text-align: center;
    color: #0f4565;
  }

  &__subtitle {

    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #47aae3;
    margin-bottom: 17px;
  }

  &__descr {

    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    text-align: center;
    color: #000;
    margin-bottom: 20px;
  }

}
