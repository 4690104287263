.collapse-wrapper {
  //position: relative;
  background-color: #191c23;
  display: flex;
  flex-shrink: 0;

  &--collapsed {
    //position: relative;
    height: 18px;
    overflow: hidden;

    .collapse-button-container {
      position: absolute;
      top: 50%;
      right: 0;
      height: 20px;
      padding-top: 0;
      transform: translateY(-50%);
    }

    & > *:not(.collapse-button-container) {
      pointer-events: none;
    }

    &.collapse-wrapper--show-header {
      height: 40px;
    }
  }
}

.collapse-button-container {
  padding-top: 20px;
  padding-right: 20px;
}
