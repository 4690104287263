.out {
  overflow-x: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 24px;
  margin-right: 24px;
  width: calc(100% - 48px);
  min-width: 256px;
  float: left;
}

.edit_form_title {
  width: calc(100% - 48px);
  min-width: 256px;
  height: 30px;
  margin-right: 24px;
  color: #4a505b;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;

  input[type='text'] {
    color: #000;
  }
}

.edit_form {
  border: 1px solid rgb(188, 194, 199);
  width: calc(100% - 48px);
  min-width: 256px;
  margin-right: 24px;

  .edit_field {
    margin-left: 12px;
    margin-right: 12px;
    position: relative;
    margin-bottom: 12px;
    float: left;
    width: 256px;
    height: 70px;

    .button_left {
      position: relative;
      top: 30px;
      height: auto;
      float: left;
    }
    .button_right {
      position: relative;
      top: 30px;
      height: auto;
      float: right;
    }
  }
}

.secondaryBtn {
  float: left;
}

.secondaryBtn--enabled {
  button {
    background-color: #51c778 !important;
    span {
      color: #fff !important;
    }
  }
}

.ops_buttons {
  width: calc(100% - 48px);
  min-width: 256px;

  .add_button {
    float: left;
    margin-right: 24px;
  }

  .dell_button {
    float: left;
    margin-left: 24px;
    margin-right: 24px;
  }

  .clear_button {
    float: right;
  }
}

.renderAddressList2Table {
  float: none !important;

  table {
    tbody {
      .checked {
        width: auto;
        min-width: 40px;
        text-align: center;
      }
      .okrug_name {
        width: auto;
        min-width: 70px;
      }
      .street_name {
        width: auto;
        min-width: 240px;
      }
      .address_name {
        width: auto;
        min-width: 160px;
      }
      .unom,
      .unad {
        width: auto;
        min-width: 80px;
      }
    }
  }
}

.renderDialogPgm {
  height: 308px;
  thead,
  tbody {
    .checked {
      width: auto;
      min-width: 40px;
    }
  }
}

.renderLinkageTable {
  .out {
    float: initial;
  }

  table {
    thead {
      tr:nth-child(2) {
        th {
          font-weight: 500;
        }
      }
    }

    thead,
    tbody {
      th,
      td {
        width: auto;
        min-width: 250px;
      }
    }
  }
}
