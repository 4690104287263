.ods-value--old,
.clean__wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.clean__wrapper_select {
  width: calc(50% - 48px);
  margin-left: 24px;
  margin-right: 24px;
  min-width: 256px;
}
