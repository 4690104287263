.dnd-file-upload {
  &__dropzone {
    width: 100%;
    padding: 20px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.2);
    border-style: dashed;
    border-radius: 5px;
  }


  &__disabled {
    color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  &__list {
    &:hover {
      box-shadow: 1px 1px 6px 0px #00000021;
      font-weight: bold;
    }
  }
}