.section-hint {
  padding: 10px;
  &__list {
    padding: 0 10px;
    counter-reset: item
  }
  &__container {}
  &__item {
    &:before { }
  }
  &__header {
    font-weight: bold;
    font-size: larger;
  }

  &__hr {
    border-top: 1px solid white;
    margin: 7px 0;
  }
}