.arrow-header-card {
  cursor: pointer;
  transform: rotate(0);

  &__active {
    transform: rotate(180deg);
  }

  & > svg {
    fill: #C4C4C4;
    transition: .2s fill;
  }

  &:hover {
    & > svg {
      fill: white;
    }
  }
}
