.item_file_list_knowledge_base {
  &__dialog {
    position: absolute;
    z-index: 2;
    top: 0;
    background: #fff;
    padding: 0;
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  &__video {
    width: 100%;
  }
  &__name--video {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
