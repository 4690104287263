.table-card-version {

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 15px;
  }


  &__message {
    color: #d21f1f;
  }

}
