.news-notes {
  &__content {
    height: 70vh;
    overflow: auto;
  }

  &__text {
    max-width: 100%;
    overflow: initial;
    display: block;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;
  }
}
