.bottom-auto {
  bottom: auto !important;
}

.right-panel {
  color: #232931;

  position: absolute;
  width: 30.5%;
  top: 24px;
  bottom: 3%;
  right: 2%;
  z-index: 4;
  display: flex;
  flex-direction: column;

  &__header {
    text-transform: uppercase;
    background: rgba(28, 42, 52, 0.95);
    color: white;
    font-size: 18px;
  }

  &__tree-toggle {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
  }

  //&__tree-toggle:after {
  //  width: 23px;
  //  height: 23px;
  //  content: '';
  //  opacity: 0.5;
  //  right: 12px;
  //  top: 24px;
  //  position: absolute;
  //}

  //&__tree-toggle.up:after {
  //  background: url(../../../../../../assets/images/ic_sprite.png) no-repeat -2px -25px;
  //}
  //
  //&__tree-toggle.down:after {
  //  background: url(../../../../../../assets/images/ic_sprite.png) no-repeat -2px -2px;
  //}

  //&__tree-toggle:hover {
  //  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.15);
  //}

  h4 {
    font-weight: normal;
    margin: 0;
  }

  &__card-toggle.card-toggle.fa.boxArea {
    color: white;
    text-transform: uppercase;
    text-shadow: none;
    padding: 8px 12px;
    text-align: center;
    background: rgba(28, 42, 52, 0.95);
    border: none;
    cursor: pointer;
    font-size: 12px;
    outline: none;
    left: -30px;
    top: 30px;
    position: absolute;

    &:hover {
      box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.15);
    }

    &:active {
      box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.15);
    }
  }


  .content {
    background: rgba(4, 4, 16, 0.9);
    height: calc(100% - 69px);
  }
}

#right-panel-content {
  background-color: rgba(4, 4, 16, 0.9);
  overflow-y: auto;
  color: white;
  position: relative;
  height: 100%;
}
