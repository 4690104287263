#tree-edit {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -640px;
  margin-top: -420px;
  width: 1280px;
  height: 840px;
  background-color: white;

  .header {
    background-color: #313846;
    color: white;
    height: 40px;
    line-height: 40px;
    padding-left: 24px;
    text-transform: uppercase;
    font-size: 14px;

    .close {
      top: -3px;
      right: 10px;
      float: right;
    }
  }

  .content {
    .tabs {
      margin: 0;
      cursor: pointer;
      text-align: left;
      text-transform: uppercase;
      padding: 4px 0;
      background-color: #f7f8f8;
      border-top: 1px solid #e9eaeb;
      padding-left: 24px;
      font-size: 13px;

      li {
        display: inline-block;
        margin-right: 20px;
      }

      li.active {
        border-bottom: 2px solid red;
      }
    }

    .report-date {
      padding: 10px;
      padding-bottom: 3px;
    }

    .version-props {
      width: 50%;
      padding: 10px;
      display: inline-block;

      & > * {
        padding: 2px;
      }
    }

    .caption {
      font-weight: bold;
      width: 175px;
      display: inline-block;
    }

    .field {
      font-style: italic;
      display: inline-block;
      text-decoration: underline;
    }
  }
}
