.list {
  white-space: normal;
}

.listNext {
  margin-left: 18px;
}

.arrow {
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  margin-right: 8px !important;
}

.li {
  white-space: nowrap;
}

.checkbox {
  & > div {
    white-space: normal;
    display: inline-table !important;
    width: auto !important;
  }
  label {
    width: 100% !important;
  }
}
