//общие стили для всех
.two-column-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  > div {
    flex: 40%
  }
  > fieldset {
    flex: 40%
  }
}
