.green {
  td {
    background-color: rgba(0, 255, 0, 0.5);
  }
}
.red {
  td {
    background-color: rgba(255, 0, 0, 0.5);
  }
}
.yellow {
  td {
    background-color: rgba(255, 255, 0, 0.5);
  }
}
