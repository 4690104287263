.branch {
  &.branch__item {
    color: #D2D2D4;
  }
}

.wrapper-branch__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 4px;
}

.wrapper-branch__title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 216px;
}
