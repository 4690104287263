@import 'assets/styles/variables';

.tabs-card-header__container {
  background-color: #f7f8f8;
  border-top: 1px solid #e9eaeb;
  text-transform: uppercase;
  margin-left: - $padding-card;
  margin-right: - $padding-card;
  padding: 0 $padding-card;
}


