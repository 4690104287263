.container{
  &__two_column{
    display: flex;
    flex-wrap: wrap;
    gap: 0 1em;
    margin: 1em 0;
    > div {
      flex: 40% 1;
    }
  }
}
