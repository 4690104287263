.ods-grid-element__header {
  display: block;
  margin-top: 16px;
  margin-left: 24px;
  color: #4a505b;
  font-size: 18px;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  height: initial;
}

.ods-grid-element__clearing {
  clear: left;
}
