.legend-table-radio {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  border-radius: 7px;
  border: white 1px solid;
  background: #e3e3e3;

  &__delete {
    background: red;
  }

  &__update {
    background: #c1c100;
  }

  &__add {
    background: green;
  }

  &__move {
    background: #1fbcd2;
  }
}

