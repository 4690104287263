.base {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  &__header {
    flex-shrink: 0;
  }
}
