.filter {
  &__form {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    gap: 20px;
    align-items: flex-end;
  }

  &__btn-submit-group {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}
