.icon-arrow-card {
  display: inline-block;
  padding: 7px;
  margin-right: 2px;
  transform: rotate(-90deg);
  transition: .1s;

  & svg {
    fill: #747474;
  }

  &__contains-child {
    & svg {
      fill: #C4C4C4;
    }
  }

  &__active {
    transform: rotate(0);
  }
}
