.navigation-table {
  padding-top: 14px;
  padding-bottom: 14px;
  min-height: 74px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .nav-header {
    display: flex;
    & .nav-label {
      line-height: 26px;
      font-size: 14px;
      color: #555;
      padding-left: 10px;
    }

    & .nav-rows {
      margin-left: 20px;
    }
  }



  .nav {
    white-space: nowrap;
    display: flex;
    align-items: center;

    padding-right: 10px;
    padding-left: 20px;

    cursor: not-allowed;
    color: #101318;

    span.next,
    span.last,
    span.prev,
    span.first {
      opacity: 0.5;
    }

    .goto a,
    .goto em,
    a.next,
    a.last,
    a.prev,
    a.first,
    span.next,
    span.last,
    span.prev,
    span.first {
      padding: 6px 12px;
      display: flex;
    }

    .goto em {
      background-color: #101318;
      color: #fff;
      font-style: normal;
    }

    a.next:hover,
    a.last:hover,
    a.prev:hover,
    a.first:hover,

    span.goto:hover {
      background: white;

    }

    a {
      color: #101318;
      text-decoration: none;
    }
  }
}
