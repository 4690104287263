.ods-field-label {
  color: black;
  font-size: 11pt;
  width: 100%;
  height: 33px;
  position: relative;
  margin-top: 0px;
  display: table-cell;
  vertical-align: bottom;
}

.ods-field-label--required::before {
  content: '*';
  color: #ff0000;
  padding-right: 4px;
}
