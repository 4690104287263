.tree-main-page__list {
  margin: 0;
  padding: 0;
  font-weight: 400;

  & li {
    margin: 0;
    padding: .6rem;
    transition: 0.3s;
    & a {
      text-decoration: none;
    }

    & span {
      opacity: .6;
    }


  }

  li:hover {
    cursor: pointer;
    background-color: #1c2b36;
    & span {
      opacity: 1;
    }
  }


}